import H2 from "components/elements/H2";
// import H3 from "components/elements/H3";

const About = () => {
  return (
    <section id="about">
      <H2>About Me</H2>
      <p>
        Working casually in Hospitality to support myself while building web applications.
        Hoping to learn enough to gain an entry level job in Web Development.
      </p>
      <p>
        Ultimately looking to build skills to the point I can develop and sell SAAS to
        businesses. I held a role in hotel management for a number of years where I built apps
        in MS Access that helped me achieve highly. The apps concerned:
        <ul className="list-disc list-inside">
          <li>
            stock ordering:{" "}
            <span className="font-bold">
              we never ran out of stock while maintaining a very low stock-on-hand value
            </span>
          </li>
          <li>
            employee compliance:{" "}
            <span className="font-bold">
              showed the status of required tasks for each employee based on their roles
            </span>{" "}
          </li>
        </ul>
        and a number of other solutions.
      </p>
      <p>
        Since then (around 2021) I've been learning as much about Web Development as I can. I
        approached it with a self-study mentality for a year then reinforced my learning by
        completing a 6 month bootcamp by the University of Adelaide in 2022. I thoroughly
        enjoyed the collaborative aspect of the bootcamp which not only helped solidify my own
        knowledge on a lot of material, but also gave me a chance to share what I know and help
        others who were just starting their journey.
      </p>
      <p>
        Having become comfortable with small-scale applications, I wanted to better understand
        how to build something more challenging. I also became interested in making software
        maintainable and scaleable. I'm currently reading Domain-Driven Design, Tackling
        Complexity in the Heart of Software by Eric Evans, as well as Versioning in an Event
        Sourced System by Gregory Young in an effort to help shape my thoughts around
        architecting a complex system where an audit trail is crucial, among other factors such
        as high-availability and data redundancy.
      </p>
    </section>
  );
};

export default About;
