import Section from "components/elements/Section";
import H2 from "components/elements/H2";

const CoinCharter = () => {
  return (
    <article>
      <Section>
        <H2>Overview</H2>
        <p>
          Completed as a 2 week final project for the bootcamp in November 2022. I worked on
          this with 2 other team-mates. Project requirements had us use React, Express.js and
          MongoDB to create a full-stack application. The only other requirements being that it
          had some kind of interactivity and stored data of our choosing.
        </p>
        <p>
          We built a social crypto-currency analysis platform that allows users to create an
          account, connect with people and most importantly, create analyses of currencies and
          post them up for review. An analysis is publicly viewable and subject to a voting
          system where the best (and most current) ones will rise to the top.
        </p>
      </Section>
      <Section>
        <H2>Implementation</H2>
        <p>
          We created our React project without bootstrapping, using Webpack as our bundler.
          This was to understand the React framework a level deeper than Create-React-App or
          Vite etc.
        </p>
        <p>
          The React application is served by a Node.js server (Express.js as the framework).
          People can use the site as an anonymous user or with a registered account. Anonymous
          users have read-only privileges.
        </p>
        <p>
          Our application is powered by the Coin Gecko api which provides us with by-the-minute
          updates on currency performance.
        </p>
      </Section>
      <Section>
        <H2>Where to from here</H2>
        <p>
          We have (soft) plans to move from MongoDB to a relational database. A lot of the data
          in the application is relational by nature and would be better suited to a RDBMS.{" "}
        </p>
        <p>
          Performance is an issue. I would love to address UX issues such as having immediate
          feedback (probably an optimistic approach) to situations like favouriting a currency.
          Server locations are terrible! We are hosting the application on Heroku (United
          States), which talks to our Mongo Atlas server in Sydney, which then bounces back to
          the US before serving us here in the (likely) AU. Sublime.
        </p>
        <p>
          The GraphQL client we are using has subscription features that would support
          real-time updates on friend/connection requests. This is another feature that would
          provide good value to our application. Feels like a slippery slope to implementing a
          scaleable event driven system though... 😂
        </p>
      </Section>
    </article>
  );
};

export default CoinCharter;
